<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item>账户设置</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_right">
                <div class="box_right_top">账户设置</div>
                <div class="box_right_cent">
                  <!-- <div class="cent_box ">
                    <div class="flex-between" @click="changePassword()">
                      <div class="flex-start">
                        <img src="@/assets/image/04.png" alt="">
                        <div class="login_pass">登录密码</div>
                        <div class="cent_text">建议您定期更改密码，提高账户安全等级</div>
                      </div>
                      <div class="cent_amend">修改>></div>
                    </div>
                  </div> -->
                  <div class="cent_box ">
                    <div class="flex-between" @click="bindEmail()">
                      <div class="flex-start">
                        <img src="@/assets/image/05.png" alt="">
                        <div class="login_pass">绑定邮箱</div>
                        <div class="cent_text" v-if="email!=''">已绑定邮箱{{email}}</div>
                        <div class="cent_text" v-else>绑定邮箱，方便找回密码</div>
                      </div>
                      <div class="cent_amend">修改>></div>
                    </div>
                  </div>
                  <div class="cent_box ">
                    <div class="flex-between" @click="bindCellphone()">
                      <div class="flex-start">
                        <img src="@/assets/image/06.png" alt="">
                        <div class="login_pass">绑定手机号</div>
                        <div class="cent_text">已验证手机号{{userPhone}}</div>
                      </div>
                      <div class="cent_amend">修改>></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import { workbench } from "@/api/order/order";
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
        userPhone:'',
        email:'',
        Phone:''
      }
  },
  created(){
    workbench().then((data)=>{
      if(data&&data.code=='00000'){
        // let Phone=data.data.userPhone
        // this.userPhone=Phone.substr(0, 3) + '****' + Phone.substr(7, 11);
        if(data.data.email!=null){
          this.email=data.data.email
        }
        this.userPhone=data.data.userPhone
      }
    })
    
  },
  methods:{
    changePassword(){
      this.$router.push({ name: 'ChangePassword' })
    },
    bindEmail(){
      this.$router.push({ name: 'BindEmail',params:{email: this.email} })
    },
    bindCellphone(){
      this.$router.push({ name: 'BindCellphone',params:{userPhone: this.userPhone} })  
    }
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_right{
    width: 87%;
    height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .cent_box{
      border-bottom: 1px solid #d7d7d7;
      height: 120px;
      line-height: 120px;
      margin: 20px 30px;
      img{
        margin: 0 50px;
      }
      .login_pass{
        font-size: 20px;
        margin-right: 60px;
      }
      .cent_text{
        font-size: 12px;
        color: #999999;
      }
      .cent_amend{
        color: #CD9F49;
        cursor: pointer;
      }
    }
  }
}
</style>