<template>
  <div>
    <div class="hurdle">
      <ul class="hurdle_box">
        <li class="box_li" v-for="(item, index) in allData" :key="index">
          <div class="box-div">{{ item.item }}</div>
          <ul v-for="(itm, indx) in item.children" :key="indx">
            <li class="stair_li">
              <a :href="itm.url">{{ itm.item }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: 1,
      // //采购审批
      // menuData: [
      //   {
      //     item: '订单中心',
      //     children: [
      //       { url: '/workbench/myOrder', item: '我的订单' },
      //       { url: '/workbench/bulkOrder', item: '批量下单' },
      //       { url: '/workbench/invoiceCenter', item: '发票中心' }
      //     ]
      //   },
      //   {
      //     item: '售后中心',
      //     children: [
      //       { url: '/workbench/changingRefund', item: '申请售后' },
      //       { url: '/workbench/salesRecord', item: '售后记录' },
      //       { url: '/workbench/procurementAccount', item: '采购账号售后记录' }
      //     ]
      //   },
      //   {
      //     item: '账号中心',
      //     children: [
      //       { url: '/workbench/myTopUp', item: '我的预充值' },
      //       { url: '/workbench/addedQualification', item: '增票资质' },
      //       { url: '/workbench/accountSettings', item: '账户设置' },
      //       { url: '/workbench/shippAddress', item: '地址管理' }
      //     ]
      //   }
      // ],
      // //采购
      // menuDatas: [
      //   {
      //     item: '订单中心',
      //     children: [
      //       { url: '/workbench/myOrder', item: '我的订单' },
      //       { url: '/workbench/bulkOrder', item: '批量下单' },
      //       { url: '/workbench/invoiceCenter', item: '发票中心' }
      //     ]
      //   },
      //   {
      //     item: '售后中心',
      //     children: [
      //       { url: '/workbench/changingRefund', item: '申请售后' },
      //       { url: '/workbench/salesRecord', item: '售后记录' }
      //     ]
      //   },
      //   {
      //     item: '账号中心',
      //     children: [
      //       { url: '/workbench/myTopUp', item: '我的预充值' },
      //       { url: '/workbench/addedQualification', item: '增票资质' },
      //       { url: '/workbench/accountSettings', item: '账户设置' },
      //       { url: '/workbench/shippAddress', item: '地址管理' }
      //     ]
      //   }
      // ],
      // //审批,管理审批
      // menuDataes: [
      //   {
      //     item: '订单中心',
      //     children: [{ url: '/workbench/myOrder', item: '审核订单' }]
      //   },
      //   {
      //     item: '售后中心',
      //     children: [
      //       { url: '/workbench/procurementAccount', item: '采购账号售后记录' }
      //     ]
      //   },
      //   {
      //     item: '账号中心',
      //     children: [
      //       { url: '/workbench/myTopUp', item: '我的预充值' },
      //       { url: '/workbench/accountSettings', item: '账户设置' },
      //       { url: '/workbench/shippAddress', item: '地址管理' }
      //     ]
      //   }
      // ],
      // //管理
      // menuDataesd: [
      //   {
      //     item: '订单中心',
      //     children: [{ url: '/workbench/myOrder', item: '所有订单' }]
      //   },
      //   {
      //     item: '售后中心',
      //     children: [
      //       { url: '/workbench/procurementAccount', item: '采购账号售后记录' }
      //     ]
      //   },
      //   {
      //     item: '账号中心',
      //     children: [
      //       { url: '/workbench/myTopUp', item: '我的预充值' },
      //       { url: '/workbench/accountSettings', item: '账户设置' },
      //       { url: '/workbench/company/index', item: '公司账号管理' }
      //     ]
      //   }
      // ],
      //  index-对应第几个tab下的数据
      // jurisdiction-配置权限的容器
      // 1-管理员，2-项目审判员，3-采购员，4-总部审批员，5-外部企业管理员，6-外部企业商品管理员
      routerList: [
        {
          url: '/workbench/myOrder',
          item: '订单列表',
          index: 0,
          jurisdiction: ['1', '2', '3', '4', '5'] // 外部全有
        },
        {
          url: '/workbench/bulkOrder',
          item: '批量下单',
          index: 0,
          jurisdiction: ['3'] // 外部采购员独有
        },
        {
          url: '/workbench/invoiceCenter',
          item: '发票中心',
          index: 0,
          jurisdiction: ['3'] // 外部采购员独有
        },
        {
          url: '/workbench/setGoodsList',
          item: '商品列表',
          index: 1,
          jurisdiction: ['6'] // 商品管理员有
        },
        {
          url: '/workbench/changingRefund',
          item: '申请售后',
          index: 2,
          jurisdiction: ['3'] // 内外部采购独有
        },
        {
          url: '/workbench/salesRecord',
          item: '售后记录',
          index: 2,
          jurisdiction: ['3'] // 内外部采购独有
        },
        {
          url: '/workbench/procurementAccount',
          item: '采购账号售后记录',
          index: 2,
          jurisdiction: ['1', '2', '4', '5'] // 内外部非采购全有
        },
        // {
        //   url: '/workbench/myBalance',
        //   item: '我的余额',
        //   index: 3,
        //   jurisdiction: ['1'] // 管理员独有
        // },
        {
          url: '/workbench/myTopUp',
          item: '我的预充值',
          index: 3,
          jurisdiction: ['5'] // 管理员独有
        },
        {
          url: '/workbench/addedQualification',
          item: '增票资质',
          index: 3,
          jurisdiction: ['1', '3', '5'] // 非审批员都有
        },
        {
          url: '/workbench/accountSettings',
          item: '账户设置',
          index: 3,
          jurisdiction: ['1', '2', '3', '4', '5', '6'] // 全有
        },
        {
          url: '/workbench/shippAddress',
          item: '地址管理',
          index: 3,
          jurisdiction: ['3'] // 采购独有
        },
        {
          url: '/workbench/company/index',
          item: '公司账号管理',
          index: 3,
          jurisdiction: ['5'] // 外部企业管理员独有
        }
      ],
      allData: []
    }
  },
  created() {
    const identity = JSON.parse(localStorage.getItem('identity'))
    // 路由容器
    let menuData = [
      {
        item: '订单中心',
        children: []
      },
      {
        item: '商品设置',
        children: []
      },
      {
        item: '售后中心',
        children: []
      },
      {
        item: '账号中心',
        children: []
      }
    ]
    // 获取账号权限id
    let identityArr = []
    identity.forEach((item) => {
      identityArr.push(item.roleId)
    })
    // 判断账号是否有该路由的权限
    this.routerList.forEach((item) => {
      if (this.getisRepeat([...item.jurisdiction, ...identityArr])) {
        menuData[item.index].children.push(item)
      }
    })
    // 一个路由都没有删除对应tab
    // for(let i = 0; i < menuData.length; i++) {
    //   if (menuData[i].children.length == 0) {
    //     menuData.splice(i, 1)
    //     i--
    //   }
    // }
    this.allData = menuData.filter( item => item.children.length > 0 )
    // 判断是否外部企业用户，有增加账单页面
    let externalId = JSON.parse(localStorage.getItem("externalId"))
    if (externalId) {
      this.allData[0].children.push({
        url: '/workbench/myBill',
        item: '我的账单',
        index: 0
      })
      // this.allData[0].children.forEach((v, i) => {
      //   if (v.item == "批量下单") {
      //     this.allData[0].children.splice(i, 1)
      //   }
      // })
    }
    // 旧逻辑
    // if (identity.length > 1) {
    //   let list = []
    //   identity.forEach((item) => {
    //     list.push(item.roleId)
    //   })
    //   if (list.length == 4) {
    //     this.allData = this.menuData
    //   } else {
    //     let a = myfunction(list, 1)
    //     let b = myfunction(list, 2)
    //     let c = myfunction(list, 3)
    //     let d = myfunction(list, 4)
    //     if (a.length && b.length && c.length) {
    //       this.allData = this.menuData
    //     } else if (a.length && b.length && d.length) {
    //       this.allData = this.menuDataes
    //     } else if (a.length && c.length && d.length) {
    //       this.allData = this.menuData
    //     } else if (b.length && c.length && d.length) {
    //       this.allData = this.menuData
    //     } else if (a.length && b.length) {
    //       this.allData = this.menuDataes
    //     } else if (a.length && c.length) {
    //       this.allData = this.menuData
    //     } else if (a.length && d.length) {
    //       this.allData = this.menuDataes
    //     } else if (b.length && c.length) {
    //       this.allData = this.menuData
    //     } else if (b.length && d.length) {
    //       this.allData = this.menuDataes
    //     } else if (c.length && d.length) {
    //       this.allData = this.menuData
    //     } else if (a.length) {
    //       this.allData = this.menuDataesd
    //     } else if (b.length) {
    //       this.allData = this.menuDataes
    //     } else if (c.length) {
    //       this.allData = this.menuData
    //     } else if (d.length) {
    //       this.allData = this.menuDataes
    //     }
    //     // eslint-disable-next-line no-inner-declarations
    //     function myfunction(arr, t) {
    //       function checkAult(p) {
    //         return p == t
    //       }
    //       return arr.filter(checkAult)
    //     }
    //   }
    // } else {
    //   identity.forEach((item) => {
    //     if (item.roleId == '3') {
    //       this.allData = this.menuDatas
    //     } else if (item.roleId == '2') {
    //       this.allData = this.menuDataes
    //     } else if (item.roleId == '4') {
    //       this.allData = this.menuDataes
    //     } else {
    //       this.allData = this.menuDataesd
    //     }
    //   })
    // }
    // if(identity.length>1){
    //     identity.forEach(item => {
    //         console.log(item)
    //         this.allData=this.menuData
    //     });
    // }else{
    //     identity.forEach(item => {
    //         console.log(item.roleName)
    //         if(item.roleName=='采购员'){
    //             this.allData=this.menuDatas
    //         }else if(item.roleName=='采购审批员'){
    //             this.allData=this.menuData
    //         }else if(item.roleName=='福利审批员'){
    //             this.allData=this.menuData
    //         }else{
    //             this.allData=this.menuDataesd
    //         }
    //     });

    // }
  },
  methods: {
    // 判断是否有重复数据
    getisRepeat(arr) {
      return arr.length != Array.from(new Set(arr)).length
    },
    MyOrder() {
      this.$router.push({ name: 'MyOrder' })
    },
    InvoiceCenter() {
      this.$router.push({ name: 'InvoiceCenter' })
    }
  }
}
</script>

<style lang="scss">
.hurdle_box {
  border: 1px solid #e4e4e4;
  border-top: 2px solid #cd9f49;
  border-bottom: none;
  width: 186px;
  .box_li {
    font-weight: bold;
    font-size: 15px;
    border-bottom: 1px solid #e4e4e4;
    ul {
      width: 185px;
    }
  }
  .box-div {
    padding: 8px 0 8px 15px;
    border-bottom: 1px solid #e4e4e4;
  }
  .stair_li {
    padding: 8px 0;
    border-bottom: 1px solid #e4e4e4;
    font-weight: 500;
    font-size: 15px;
    padding-left: 25px;
    a {
      color: #333;
      text-decoration: none;

      &:hover {
        color: #cd9f49;
      }
    }
  }
  // li:first-child{
  //     border-bottom:none
  // }
}
</style>