import request from '@/utils/request'
// import qs from 'qs'


/**
 * 获取下单信息
 * @param data
 */
export function orderInfro(data) {
    return request({
        url: 'orders/order/orders/goodsInfoList',
        method: 'post',
        data
    })
}

/**
 * 获取地址
 * @param data
 */
export function getAddress(data) {
    return request({
        url: 'orders/order/orders/getAddress',
        method: 'post',
        data
    })
}

/**
 * 获取可选邮箱地址（内部用户）
 * @param data
 */
export function getInvoiceEmail(data) {
    return request({
        url: 'orders/order/orders/getInvoiceEmail',
        method: 'post',
        data
    })
}

/**
 * 查看其它地址
 * @param data
 */
export function restaddress(data) {
    return request({
        url: 'orders/order/orders/getAddressList',
        method: 'post',
        data
    })
}

/**
 * 新增地址
 * @param data
 */
export function newlyAdd(data) {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/addAddress',
        method: 'post',
        data
    })
}

/**
 * 获取发票
 * @param data
 */
export function getInvoice(data) {
    return request({
        url: 'orders/order/orders/getInvoiceConfig',
        method: 'post',
        data
    })
}

/**
 * 获取其它发票
 * @param data
 */
export function getrestInvoice(data) {
    return request({
        url: 'orders/order/orders/getInvoiceConfigList',
        method: 'post',
        data
    })
}

/**
 * 计算订单
 * @param data
 */
export function calculateOrder(data) {
    return request({
        url: 'orders/order/orders/confirmorderInfo',
        method: 'post',
        data
    })
}

/**
 * 获取订单列表
 * @param data
 */
export function addressList(data) {
    return request({
        url: 'orders/orderMsg/queryOrderList',
        method: 'post',
        data
    })
}

/**
 * 查询订单详情
 * @param id
 */
export function queryOrderDetails(id) {
    return request({
        url: `/orders/orderMsg/queryOrderList/${id}`,
        method: 'post',
    })
}

/**
 * 获取地址列表
 * @param data
 */
export function goodsAddress(data) {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/queryOrderList',
        method: 'post',
        header: {
            'content-type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

/**
 * 提交订单
 * @param data
 */
export function submitOrder(data) {
    return request({
        url: 'orders/order/orders/submitOrder',
        method: 'post',
        data
    })
}

/**
 * 删除订单
 * @param id
 */
export function deleteOrder(id) {
    return request({
        url: 'orders/order/orders/' + id,
        method: 'DELETE',
        header: {
            'content-type': 'application/x-www-form-urlencoded'
        },
    })
}

/**
 * 导出订单
 * @param data
 */
export function exportOrders(data) {
    return request({
        url: 'orders/orderMsg/exportOrder',
        method: 'PUT',
        responseType: 'blob',
        timeout: 1000 * 60 * 10,
        data
    })
}


/**
 * 审核订单
 * @param data
 */
export function auditOrders(data) {
    return request({
        url: 'orders/orderMsg/auditOrders',
        method: 'put',
        header: {
            'content-type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

/**
 * 
新增认款申请单
 * @param data
 */
export function saveEntity(data) {
    return request({
        url: 'orders/order/subscription-application-form/saveEntity',
        method: 'post',
        data
    })
}

/**
 * 
编辑认款申请单
 * @param data
 */
export function editEntity(data) {
    return request({
        url: 'orders/order/subscription-application-form/editEntity',
        method: 'post',
        data
    })
}

/**
 * 
上传汇款凭证
 * @param data
 */
export function uploadImaage(data) {
    return request({
        url: 'orders/order/subscription-application-form/uploadImaage',
        method: 'post',
        header: {
            'content-type': 'application/x-www-form-urlencoded'
        },
        data
    })
}


/**
 * 
订单状态详细
 * @param data
 */
export function orderafter(data) {
    return request({
        url: 'orders/orderMsg/queryOrderStatus',
        method: 'post',
        data
    })
}


/**
 * 
订单状态详细
 * @param data
 */
export function invoiceCenter(data) {
    return request({
        url: 'orders/order/invoice/queryInvoiceList',
        method: 'post',
        data
    })
}


/**
 * 
工作台
 * @param data
 */
export function workbench() {
    return request({
        url: 'admin/admin/base-user/getWorkPlatformInfo',
        method: 'post'
    })
}


/**
 * 
工作台未支付
 * @param data
 */
export function getWorkInfo() {
    return request({
        url: 'orders/order/orders/getWorkInfo',
        method: 'post'
    })
}


/**
 * 
地址设置默认
 * @param data
 */
export function Setdefault(data) {
    return request({
        url: 'admin/admin/bulk-purchase-employee-address/editIsDefault',
        method: 'post',
        data

    })
}

/**
 * 
获取重新购买信息
 * @param data
 */
export function buyAgain(data) {
    return request({
        url: 'orders/order-goods/againOrder',
        // url: 'orders/order/orders/getGoodsInfoList',
        method: 'post',
        data
    })
}

/**
 * 
重新加入购物车
 * @param data
 */
export function addToCartAgain(data) {
    return request({
        url: 'orders/order-goods/againAddShoppingCart',
        method: 'post',
        data

    })
}


/**
 * 
取消订单
 * @param data
 */
export function unhideorder(data) {
    return request({
        url: 'orders/order/orders/cancelOrder',
        method: 'post',
        data

    })
}


/**
 * 
确认收货
 * @param data
 */
export function affirmorder(data) {
    return request({
        url: 'orders/order/orders/confiiirmReceipt',
        method: 'post',
        data

    })
}


/**
 * 
修改发票信息
 * @param data
 */
export function editInvoice(data) {
    return request({
        url: '/orders/order/invoice/editInvoice',
        method: 'post',
        data

    })
}


/**
 * 
查询是否提交凭证
 * @param data
 */
export function isSubmit(data) {
    return request({
        url: '/orders/order/subscription-application-form/isSubmit',
        method: 'post',
        data

    })
}

/**
 * 
订单审核状态
 * @param data
 */
export function auditStatus(data) {
    return request({
        url: '/orders/orderMsg/queryOrderAuditStatus',
        method: 'post',
        data

    })
}

/**
 * 
查询批量下单导入的下单数据
 * @param data
 */
export function getBatchList(data) {
    return request({
        url: '/orders/order/batchOrder/getBatchList',
        method: 'post',
        data

    })
}

/**
 * 
查询下单的商品清单信息
 * @param data
 */
export function goodsInfoList(data) {
    return request({
        url: '/orders/order/batchOrder/goodsInfoList',
        method: 'post',
        data

    })
}

/**
 * 
导入批量下单模板
 * @param data
 */
export function importBatchOrder(data) {
    return request({
        url: '/orders/order/batchOrder/importBatchOrder',
        method: 'PUT',
        header: {
            'content-type': 'multipart/form-data'
        },
        data
    })
}


/**
 * 
更改批量下单地址
 * @param data
 */
export function updateBatchOAddress(data) {
    return request({
        url: 'orders/order/batchOrder/updateBatchOAddress',
        method: 'post',
        data
    })
}

/**
 * 
批量下单确认订单价格计算以及可售性判断
 * @param data
 */
export function confirmBatchOrderInfo(data) {
    return request({
        url: 'orders/order/batchOrder/confirmBatchOrderInfo',
        method: 'post',
        data
    })
}

/**
 * 
批量提交订单
 * @param data
 */
export function batchSubmitOrder(data) {
    return request({
        url: 'orders/order/batchOrder/batchSubmitOrder',
        method: 'post',
        data
    })
}

/**
 * 
导入福利卡明细
 * @param data
 */
export function importWelfareCard(data) {
    return request({
        url: 'orders/order/importWelfareCard',
        method: 'post',
        header: {
            'content-type': 'multipart/form-data'
        },
        data
    })
}


/**
 * 
导入福利卡明细
 * @param data
 */
export function uploadFiles(data) {
    return request({
        url: 'orders/orderMsg/uploadFiles',
        method: 'post',
        data
    })
}


/**
 * 
获取下单用户信息
 * @param data
 */
export function getPurchaserInformation(data) {
    return request({
        url: '/orders/orderMsg/getPurchaserInformation',
        method: 'post',
        data
    })
}
// export function getPurchaserInformation(data) {
//     return request({
//         url: 'cpuser/user/user/getPurchaserInformation?orderId='+data,
//         method: 'post',
//     })
// }

/**
 * 
获取用户可选的归属公司和归属项目
 * @param data
 */
export function getOrderAscription(data) {
    return request({
        url: '/orders/order/orders/getOrderAscription',
        method: 'post',
        data
    })
}

/**
 * 
查询下级审批员
 * @param data
 */
export function queryNextAuditor(data) {
    return request({
        url: '/orders/orderMsg/queryNextAuditor',
        method: 'PUT',
        data
    })
}

/**
 * 
获取购卡模板地址
 * @param 
 */
export function queryCartModel(id) {
    return request({
        url: `/orders/orderMsg/queryCartModel/${id}`,
        method: 'PUT',
    })
}

/**
 * 
上传审批文件
 * @param 
 */
export function uploadApproveFile(data) {
    return request({
        url: '/orders/order/uploadFile/uploadFiles',
        method: 'POST',
        data
    })
}

/**
 * 
删除审批文件
 * @param 
 */
export function delApproveFile(ids) {
    return request({
        url: `/orders/order/uploadFile/${ids}`,
        method: 'DELETE',
    })
}

/**
 * 
查看审批文件列表
 * @param 
 */
export function lookApproveFile(data) {
    let param = ''
    if(data.id) param += `id=${data.id}&`
    if(data.type) param += `type=${data.type}`
    return request({
        url: `/orders/order/uploadFile/queryList?${param}`,
        method: 'GET',
    })
}

/**
 * 
获取是否有异常未签收订单
 * @param 
 */
export function getSendErrorOrderNums() {
    return request({
        url: `/orders/order/orders/getSendErrorOrderNums`,
        method: 'POST',
    })
}

/**
 * 
发货提醒
 * @param 
 */
export function findDeliveryRuleByGoods(goodsId) {
    return request({
        url: `/goods/rule/delivery/findDeliveryRuleByGoods/${goodsId}`,
        method: 'GET',
    })
}

/**
 * 
查看所在公司的可用余额
 * @param 
 */
export function balanceQuery() {
    return request({
        url: `/orders/order/balance/query`,
        method: 'GET',
    })
}

/**
 * 
新增预充值认款单
 * @param 
 */
export function preChargeSubSave(data) {
    return request({
        url: `/orders/order/pre-charge-sub/save`,
        method: 'POST',
        data
    })
}

/**
 * 
【PC端】查询当前所在公司的余额流水
 * @param 
 */
export function queryLog(data) {
    return request({
        url: `/orders/order/balance-log/queryLog`,
        method: 'POST',
        data
    })
}

/**
 * 
查看预充值认款单
 * @param 
 */
export function preChargeSubQuery(id) {
    return request({
        url: `/orders/order/pre-charge-sub/query/${id}`,
        method: 'GEt'
    })
}

/**
 * 预充值表记录导出
 * @param data
 */
export function exportPreChargeList(params) {
    return request({
        url: '/orders/order/balance-log/exportBalanceLogList',
        method: 'GET',
        responseType: 'blob',
        params
    })
}

/**
 * 预充值认款单编辑
 * @param data
 */
export function PreChargeSubChange(data) {
    return request({
        url: '/orders/order/pre-charge-sub/change',
        method: 'POST',
        data
    })
}

/**
 * 预充值认款单编辑
 * @param data
 */
export function preChargeSubqueryList(data) {
    return request({
        url: '/orders/order/pre-charge-sub/queryList',
        method: 'POST',
        data
    })
}

/**
 * 
获取当前订单的结算方式
 * @param 
 */
export function queryOrderSettlementPeriod(orderId) {
    return request({
        url: `/orders/orderMsg/queryOrderSettlementPeriod?orderId=${orderId}`,
        method: 'GEt'
    })
}

/**
 * 获取订单审核状态信息(外部企业)
 * @param data
 */
export function queryExternalOrderAuditStatus(orderId) {
    return request({
        url: `/orders/orderMsg/queryExternalOrderAuditStatus?orderId=${orderId}`,
        method: 'GEt'
    })
}

/**
 *  * 账单列表
 */
export function billQueryBillList(data) {
    return request({
      url: `/orders/order/external-bill-msg/myBill`,
      method: 'POST',
      data
    })
}

/**
 *  * 上传支付凭证(常规结算)
 */
export function uploadPayment1(data) {
    return request({
      url: `/orders/order/external-bill-payment/uploadPayment`,
      method: 'POST',
      data
    })
}

/**
 *  * 上传支付凭证(定金结算)
 */
export function uploadPayment2(data) {
    return request({
      url: `/orders/order/external-bill-payment/uploadPayment2`,
      method: 'POST',
      data
    })
}
  
/**
 *  * 确定账单
 */
export function confirmBill(billNo) {
    return request({
      url: `/orders/order/external-bill-audit/confirmBill?billNo=${billNo}`,
      method: 'GET'
    })
}
  
/**
 *  * 确定账单
 */
export function cancelBill(billNo) {
    return request({
      url: `/orders/order/external-bill-audit/cancelBill?billNo=${billNo}`,
      method: 'GET'
    })
}

/**
 *  * 查看支付凭证
 */
export function queryPayment(params) {
    return request({
      url: `/orders/order/external-bill-payment/queryPayment`,
      method: 'GET',
      params
    })
}  

/**
 *  * 账单详情
 */
export function queryBillInfo(data) {
    return request({
      url: `/orders/order/external-bill-msg/queryBillInfo`,
      method: 'POST',
      data
    })
}  

/**
 *  * 批量上传支付凭证
 */
export function uploadBatchPayment(data) {
    return request({
      url: `/orders/order/external-bill-payment/uploadBatchPayment`,
      method: 'POST',
      data
    })
}  

/**
 * 
 * 导出外部企业商品
 * @param params
 */
export function exportBillInfo(data) {
    return request({
        url: `/orders/order/external-bill-msg/exportBillInfo`,
        method: 'POST',
        responseType: 'blob',
        data
    })
}

/**
 * 
 * 账单明细(导出)
 * @param params
 */
export function exportMyBillList(data) {
    return request({
        url: `/orders/order/external-bill-msg/exportMyBillList`,
        method: 'POST',
        responseType: 'blob',
        data
    })
}

/**
 * 修改电子卡信息
 * @param {*} data
 */
export function updateByCard(data) {
    return request({
        url: `/card/card/elCard/updateByCard`,
        method: 'post',
        data,
    })
}

/**
 * 判断导出按钮是否显示
 * @param {*} data
 */
export function isShowCardExport(data) {
    return request({
        url: `/orders/order/isShowCardEpport`,
        method: 'post',
        data,
    })
}

/**
 * 导出发卡明细预览
 * @param {*} data
 */
export function exporCardList(data) {
    return request({
        url: `/orders/order/exporCardList`,
        method: 'put',
        responseType: 'blob',
        timeout: 1000 * 60 * 10,
        data,
    })
}
